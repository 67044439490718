import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { OOSRefreshTime } from "../../../../Constants";
import {
  GetColorPallets,
  GetLastRefreshDateTime,
  GetOOSWidgetData,
  GetWidgetSettings,
} from "../../../../Helper/Services";
import OOSD3Component from "./OOSD3Component";
import {
  GetFormattedGridData,
  IsSharedFilterAlert,
} from "../../../../Helper/WidgetDataCommon";
import NoShowDiv from "../../../UI/NoShowTemplate";
import { GetColorPalletList } from "./OOSServices";

const OpenOpportunitySummaryWidget = (props) => {
  const sessionId = useSelector((state) => state.login.sessionId);
  const email = useSelector((state) => state.login.userDetail.email);
  const sharedEmail = useSelector((state) => state.layout.SharedUserEmail);
  const isOtherUserPage = useSelector((state) => state.layout.IsOtherUserPage);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [opportunities, setOpportunities] = useState([]);
  const [salesPersons, setSalesPersons] = useState([]);
  const [slots, setSlots] = useState([]);
  const [axisMax, setAxisMax] = useState(0);
  const [screenMsg, setScreenMsg] = useState("No Data");
  const [showNoData, setShowNoData] = useState(false);
  const [wonDollarValue, setWonDollarValue] = useState(0);
  const [stages, SetStages] = useState([]);
  const [colorPallet, SetColorPallet] = useState([]);

  useEffect(() => {
    if (sessionId !== "" && sessionId != null) {
      getWidgetSetting();
      GetWidgetSize();
    }

    window.addEventListener("resize", () => {
      GetWidgetSize();
    });

    const refreshWidget = setInterval(() => {
      console.log("OOS set Timout..", new Date().toLocaleTimeString());
    }, OOSRefreshTime);

    return () => {
      //clear setinterval on unmount component
      clearInterval(refreshWidget);
    };
  }, [sessionId, props.RefreshVersion, email]);

  const getWidgetSetting = () => {
    let isSharedFilter = false;
    let sharedFilterUserId = "";
    let filterId = "";

    GetWidgetSettings(props.widgetId)
      .then((widgetSettings) => {
        setShowNoData(false);
        if (
          widgetSettings["IsSharedFilter"] !== undefined &&
          widgetSettings["IsSharedFilter"] === "true"
        ) {
          isSharedFilter = true;
          sharedFilterUserId = widgetSettings["OriginalUserId"];
          filterId = widgetSettings["FilterId"];
        }

        const axisMaxVal = parseInt(widgetSettings["xAxisValue"]);
        setAxisMax(axisMaxVal);
        const axisPart = Math.round(widgetSettings["xAxisValue"] / 4);

        const arrOfSlot = Array.from({ length: 4 }, (v, index) => {
          const start = index * axisPart + (index === 0 ? 0 : 1);
          const end = index === 3 ? axisMaxVal : axisPart * (index + 1);
          const xAxis =
            index === 3 ? `>${axisPart * index} days` : `${start}-${end} days`;

          return { indexVal: index, start, end, xAxis };
        });

        setSlots(arrOfSlot.map((x) => x.xAxis));

        GetOOSWidgetData(
          widgetSettings["Filter"],
          widgetSettings["reports"],
          widgetSettings["connection"],
          sessionId,
          isOtherUserPage,
          sharedEmail,
          isSharedFilter,
          sharedFilterUserId,
          filterId
        )
          .then((widgetDataResponse) => {
            let axisColl = [];
            GetLastRefreshDateTime(widgetSettings["reports"], "", sessionId)
              .then((lstReponseDt) => {
                props.onGetNotification(lstReponseDt);

                if (IsSharedFilterAlert(widgetDataResponse)) {
                  setScreenMsg(widgetDataResponse);
                  setShowNoData(true);
                  return false;
                }
                GetColorPallets(
                  widgetSettings["reports"],
                  "salesPerson",
                  sessionId
                )
                  .then((colorsResp) => {
                    const getFormattedDt =
                      GetFormattedGridData(widgetDataResponse);

                    if (getFormattedDt.length === 0) {
                      setShowNoData(true);
                    }

                    //unique salesperson
                    const getUniqueSalesperson = [
                      ...new Set(getFormattedDt.map((x) => x.salesPerson)),
                    ].sort();
                    setSalesPersons(getUniqueSalesperson);

                    //colorPallet collection
                    const colorPalletBySalesPerson = GetColorPalletList(
                      colorsResp,
                      getUniqueSalesperson
                    );
                    SetColorPallet(colorPalletBySalesPerson);

                    const uniqueStages = [
                      ...new Set(
                        getFormattedDt
                          .sort(function (a, b) {
                            return (
                              parseInt(b.stageNumber) - parseInt(a.stageNumber)
                            );
                          })
                          .map((x) => x.stageName)
                      ),
                    ];
                    const isWonExist = uniqueStages.filter(
                      (x) => x.toLowerCase() === "won"
                    );

                    if (isWonExist.length === 0) {
                      uniqueStages.splice(0, 0, "Won");
                    }

                    SetStages(uniqueStages);

                    const filterDataAsPerAxis = getFormattedDt.filter(
                      (x) => parseInt(x.daysOpen) <= axisMaxVal
                    );

                    //filter by won and open
                    const wonOpportunity = filterDataAsPerAxis.filter(
                      (x) => x.status.toLowerCase() === "won"
                    );
                    const openOpportunity = filterDataAsPerAxis
                      .filter((x) => x.status.toLowerCase() === "open")
                      .sort(function (a, b) {
                        return (
                          parseInt(b.stageNumber) - parseInt(a.stageNumber)
                        );
                      });

                    //map won data
                    const mapDataWonOpp = ["Won"].map((stageNameObj) => {
                      axisColl = [];
                      const getDataByStageName = wonOpportunity
                        .filter(
                          (x) =>
                            x.status.toLowerCase() ===
                            stageNameObj.toLowerCase()
                        )
                        .map((bubbleData) => {
                          const xAxisOnDay = arrOfSlot.filter(
                            (x) =>
                              parseInt(bubbleData.daysOpen) >=
                                parseInt(x.start) &&
                              parseInt(bubbleData.daysOpen) <= parseInt(x.end)
                          );

                          let groupAxis = 0;
                          if (xAxisOnDay.length > 0) {
                            const existingCount = axisColl.filter(
                              (x) => x === xAxisOnDay[0].xAxis
                            ).length;
                            groupAxis = existingCount;
                            axisColl.push(xAxisOnDay[0].xAxis);
                          }

                          let textName = widgetSettings.bubbleText;
                          if (widgetSettings.bubbleText === "companyName") {
                            textName = "organization";
                          }

                          return {
                            text: bubbleData[textName],
                            dollarValue: bubbleData.opportunity,
                            activityCount: bubbleData.activityCount,
                            day: bubbleData.daysOpen,
                            "x-axis":
                              xAxisOnDay.length > 0 ? xAxisOnDay[0].xAxis : "",
                            groupIndex: groupAxis,
                            salesPerson: bubbleData.salesPerson,
                            organization: bubbleData.organization,
                          };
                        });

                      return {
                        stageName: stageNameObj,
                        bubbles: getDataByStageName,
                      };
                    });

                    if (
                      mapDataWonOpp.length > 0 &&
                      mapDataWonOpp[0].bubbles.length > 0
                    ) {
                      const sumOfWonDollarValue = mapDataWonOpp[0].bubbles
                        .map((x) => x.dollarValue)
                        .reduce((prev, next) => prev + next);
                      setWonDollarValue(sumOfWonDollarValue);
                    }

                    //map open data
                    const uniqueStageNameOpenOpp = uniqueStages.filter(
                      (x) => x.toLowerCase() !== "won"
                    );

                    const mapDataOpenOpp = uniqueStageNameOpenOpp.map(
                      (stageNameObj) => {
                        axisColl = [];
                        const getDataByStageName = openOpportunity
                          .filter(
                            (x) =>
                              x.stageName.toLowerCase() ===
                              stageNameObj.toLowerCase()
                          )
                          .map((bubbleData) => {
                            const xAxisOnDay = arrOfSlot.filter(
                              (x) =>
                                parseInt(bubbleData.daysOpen) >=
                                  parseInt(x.start) &&
                                parseInt(bubbleData.daysOpen) <= parseInt(x.end)
                            );

                            let groupAxis = 0;
                            if (xAxisOnDay.length > 0) {
                              const existingCount = axisColl.filter(
                                (x) => x === xAxisOnDay[0].xAxis
                              ).length;
                              groupAxis = existingCount;
                              axisColl.push(xAxisOnDay[0].xAxis);
                            }

                            let textName = widgetSettings.bubbleText;
                            if (widgetSettings.bubbleText === "companyName") {
                              textName = "organization";
                            }

                            let daysOpen = bubbleData.daysOpen;
                            if (bubbleData.daysOpen === 0) {
                              const getUpdatedDay = Math.round(axisPart / 4);
                              daysOpen = getUpdatedDay;
                            }

                            return {
                              text: bubbleData[textName],
                              dollarValue: bubbleData.opportunity,
                              activityCount: bubbleData.activityCount,
                              day: daysOpen,
                              "x-axis":
                                xAxisOnDay.length > 0
                                  ? xAxisOnDay[0].xAxis
                                  : "",
                              groupIndex: groupAxis,
                              salesPerson: bubbleData.salesPerson,
                              organization: bubbleData.organization,
                            };
                          });
                        return {
                          stageName: stageNameObj,
                          bubbles: getDataByStageName,
                        };
                      }
                    );

                    //map all opportunites
                    const opportunities = [...mapDataWonOpp]
                      .concat(mapDataOpenOpp)
                      .map((item) => {
                        const bubbles = item.bubbles.filter(
                          (x) => x["x-axis"] !== ""
                        );
                        item.bubbles = bubbles;
                        return item;
                      });
                    setOpportunities(opportunities);
                    console.log("opportunities data", opportunities);
                  })
                  .catch((err) => {
                    console.error("GetColorPallet:-", err);
                  });
              })
              .catch((err) => {
                console.error("GetLastRefreshDateTime:-", err);
              });
          })
          .catch((err) => {
            console.error("GetBubbleWidgetData:-", err);
          });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const GetWidgetSize = () => {
    const getParentContainer = document.getElementById(
      "content_" + props.widgetId
    );
    if (getParentContainer !== undefined && getParentContainer != null) {
      const getHeight = getParentContainer.clientHeight;
      const getWidth = getParentContainer.clientWidth;
      setHeight(getHeight);
      setWidth(getWidth);
    }
  };
  return (
    <React.Fragment>
      {showNoData === false && (
        <div>
          {height > 0 && width > 0 && (
            <OOSD3Component
              height={height}
              width={width}
              getWidgetSize={GetWidgetSize}
              widgetDt={opportunities}
              slots={slots}
              xAxisMax={axisMax}
              wid={props.wid}
              widgetId={props.widgetId}
              wonDollarValue={wonDollarValue}
              salesPersons={salesPersons}
              stages={stages}
              collorPallet={colorPallet}
            />
          )}
        </div>
      )}
      {showNoData === true && <NoShowDiv screenMsg={screenMsg} />}
    </React.Fragment>
  );
};

export default OpenOpportunitySummaryWidget;
